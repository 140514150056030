<template>
  <div class="middle-box text-center loginscreen animated fadeInDown login-div">
    <div>
      <div style="align: center">
        <img src="img/logo.svg" class="login-logo" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import Footer from "../components/common/Footer.vue";
import AxiosService from '../plugin/AxiosService';
import Constant from '../plugin/Constant';
export default {
  components: {
    Footer,
  },
  created: function () {
    
  },
}; 
</script>

<style scoped>
.login-logo {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loginscreen.middle-box {
  width: 300px;
  margin: 0 auto;
  padding: 150px 20px 180px 20px;
}
</style>
