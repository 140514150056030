<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const default_layout = "default";
const body_class = "gray-bg";
var body = $('body');
export default {
  computed: {
    layout() {
      if (this.$route.meta.layout == default_layout) {
        body.addClass(body_class);
      } else {
        body.removeClass(body_class);
      }
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>
