"use strict";
import Constant from './Constant'
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
var _ = require("lodash");

/**
 * Initalized Form Wizard
 * @param {*} id : wizard id
 */
var initalizeWizard = function (id) {
    $("#" + id).steps();
}

/**
 * Initalized select2 tag
 * @param {*} id : select2 Id
 * @param {*} optionData : data array
 */
var initalizedSelect2 = function (id, optionData) {
    $(id).select2({
        data: optionData,
    });
}

/**
 * Filter menu by id and return menu name or display name
 * @param {*} key 
 */
var getDisplayMenuNameById = function (key) {
    var filterMenu = _.filter(
        JSON.parse(localStorage.getItem(Constant.data.MENU_LIST)),
        { menu: key }
    ).map((v) => v.displayName);
    return filterMenu[0];
}

/**
 * Clear object or array
 * @param  {...any} obj 
 */
var clearData = function (...obj) {
    if (obj != undefined) {
        for (var i = 0; i < obj.length; i++) {
            _.remove(obj[i]);
        }
    }
}

/**
 * Clear object or array
 * @param  {...any} obj 
 */
var clearDataAndTimeSeriesDefault = function (...obj) {
    clearData(obj);
    return {t: moment().format('MMM DD YYYY'), y: 0};
}


/**
 * Function use for return checked input box from table
 * @param {*} tableId 
 */
var tableSelectedCheckedValue = function (tableId) {
    var table = $("." + tableId).DataTable();
    return table
        .rows({ selected: true })
        .$('input[type="checkbox"]')
        .map(function () {
            return $(this).prop("checked") ? $(this).closest("tr") : null;
        });
}

/**
 * Represent tree structured json data 
 * @param {*} jsonData 
 * @param {*} jsTreeId 
 */
var jsTreeView = function (jsonData, jsTreeId) {
    var jsTree = $("#" + jsTreeId).jstree({
        core: {
            data: jsonData,
        },
        plugins: ["types"],
        types: {
            'default': {
                'icon': 'fa fa-angle-right fa-fw'
            },
            'f-open': {
                'icon': 'fa fa-folder-open fa-fw'
            },
            'f-closed': {
                'icon': 'fa fa-folder fa-fw'
            }
        }
    }).bind("loaded.jstree", function (event, data) {
        $(this).jstree("open_all");
    });

    $("#" + jsTreeId).on('open_node.jstree', function (event, data) {
        data.instance.set_type(data.node, 'f-open');
    });
    $("#" + jsTreeId).on('close_node.jstree', function (event, data) {
        data.instance.set_type(data.node, 'f-closed');
    });
}

/**
 * Calculate object size
 * @param {*} data 
 */
var objectSize = function (data) {
    return _.size(data);
}

/**
 * Destroy json tree if exist
 * @param {*} jsTreeId 
 */
var jsTreeDestroy = function (jsTreeId) {
    try {
        $("#" + jsTreeId).jstree().destroy();
    } catch (error) { }
}

/**
 * Json tree menu create
 * @param {*} data 
 */
var jsTreeMenuDataCreate = function (data) {
    var menuData = []
    $.each(JSON.parse(JSON.stringify(data)), function (parentKey, parentValue) {
        menuData.push({ text: getMenuDisplayName(parentKey) });
        var children = [];
        $.each(JSON.parse(JSON.stringify(parentValue)), function (key, value) {
            children.push({ text: value.displayName });
        });
        menuData.push({ 'children': children });
    });
    return menuData;
}

/**
 * Set value in select2
 * @param {*} id 
 * @param {*} value 
 */
var setSelect2Value = function (id, value) {
    $(id).select2("val", value);
}

/**
 * Get Menu Name
 * @param {*} key 
 */
var getMenuDisplayName = function (key) {
    return _.filter(JSON.parse(localStorage.getItem(Constant.data.MENU_LIST)), { menu: key }).map(v => v.displayName);
}

/**
 * Select check box provided id
 * @param {*} id 
 */
var checkboxChecked = function (id) {
    $('#' + id).prop('checked', true);
}

/**
 * verify menu is selected or not
 * @param {*} data 
 */
var selectedMenuChecked = function (data) {
    $.each(data, function (key, value) {
        $.each(value, function (menuKey, menuValue) {
            checkboxChecked(menuValue.menu);
        });
    });
}

/**
 * Assign selected check box 
 * @param {*} id 
 * @param {*} data 
 */
var selectedApiChecked = function (id, data) {
    $("." + id + " tr td input").each(function (index, td) {
        if (_.filter(data, { id: Number($(this).attr('id')) }).length > 0) {
            $(this).prop('checked', true);
        }
    });
}

/**
 * Get Encrypted password
 * @param {*} password 
 */
var getEncryptedPassword = function (password) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.SHA256(password).toString()));
}

/**
 * Filter object
 * @param {*} object 
 * @param {*} match 
 */
var objectFilter = function name(object, match) {
    return _.filter(object, match);
}

/**
 * Checks if path is a direct property of object
 * @param {*} object 
 * @param {*} value 
 */
var hasValue = function (object, value) {
    return _.has(object, value);
}

/**
 * Filter and return api defination base on key
 * @param {*} data 
 * @param {*} key 
 */
var getApiDefinition = function (data, key) {
    return _.filter(data, { name: key }).map(v => v.definitionFile);
}

/**
 * Split string by separator and limit
 * @param {*} str 
 * @param {*} separator 
 * @param {*} limit 
 */
var splitStr = function (str, separator, limit) {
    return _.split(str, separator, limit);
}

/**
 * Remove whitespace from String
 * @param {*} str 
 */
var trimStr = function (str) {
    if (!isEmptyStr(str)) {
        return _.trim(str);
    } else {
        return '';
    }
}

/**
 * Check string is empty or not
 * @param {*} str 
 */
var isEmptyStr = function (str) {
    return _.isEmpty(str);
}

/**
 * Generate UUID
 */
var uuid = function () {
    return uuidv4();
}

/**
 * Schema Editor Initalizer 
 * @param {*} assignValue 
 * @param {*} object 
 * @param {*} self 
 */
var editSchema = function (assignValue, object, self) {
    var initial = object;
    var editorOptions = {};
    var element = document.getElementById("schemaContainer");
    try {
        this.schemaEditor = new JSONEditor(element, editorOptions);
        this.schemaEditor.set(initial);
        this.schemaEditor.expandAll();
        schemaEditorClose = function () {
            this.schemaEditor.destroy();
            $("#myModal").modal("hide");
        }.bind(this);
        schemaEditorSave = function () {
            assignValue = JSON.stringify(
                this.schemaEditor.get(),
                null,
                4
            );
            schemaEditorClose();
        }.bind(this);
        $("#schemaModalTitle").text("Schema Editor ");
        $("#myModal").modal("show");

    } catch (ex) {
        console.log(ex);
    }
    self.$forceUpdate();
}

/**
 * Rename object key
 * @param {*} object 
 * @param {*} key 
 * @param {*} newKey 
 */
var renameKey = function (object, key, newKey) {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;
    return clonedObj;
}

var datePickerInitalizer = function(id, limit) {
    $('input[name="'+id+'"]').daterangepicker({
        dateLimit: { days: limit },
        showDropdowns: true,
        locale: {
          format: "YYYY-MM-DD",
          cancelLabel: "Clear",
        },
        minDate: "12/01/2020",
        maxDate: moment(new Date()).format("MM/DD/YYYY"),
      });
      $('input[name="datefilter"]').on("cancel.daterangepicker", function (ev, picker) {
        $(this).val("");
      });
  
      $('input[name="datefilter"]').on("apply.daterangepicker", function (ev, picker) {
        $('#datefilter').val(  
          picker.startDate.format("YYYY-MM-DD") +
          " : " +
          picker.endDate.format("YYYY-MM-DD"));
      });
}

var getParametersObject = function(array) {
    var parameterArray = [];
    $.each(array, function (key, value) {
        parameterArray.push({'name' : value.name, 'in' : value.in, 'default' : value.schema.default});
      });
      return parameterArray;
}



export default {
    initalizeWizard,
    initalizedSelect2,
    getDisplayMenuNameById,
    clearData,
    tableSelectedCheckedValue,
    jsTreeView,
    jsTreeDestroy,
    jsTreeMenuDataCreate,
    objectSize,
    setSelect2Value,
    selectedApiChecked,
    selectedMenuChecked,
    checkboxChecked,
    getEncryptedPassword,
    objectFilter,
    hasValue,
    splitStr,
    isEmptyStr,
    trimStr,
    uuid,
    editSchema,
    getApiDefinition,
    renameKey,
    datePickerInitalizer,
    clearDataAndTimeSeriesDefault,
    getParametersObject
}