<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ breadcrumbList.name }} Form</h2>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="breadcrumbList.link"> {{ breadcrumbList.name }} </router-link>
        </li>
        <li class="breadcrumb-item">
          <a>Forms</a>
        </li>
        <li class="breadcrumb-item active">
          <strong> {{ breadcrumbList.displayName }} </strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-2"></div>
  </div>
</template>
<script>
import Utils from '../../plugin/Utils';


export default {
  data() {
    return {
      breadcrumbList: {
        name: "",
        link: "",
        displayName: "",
        match: "",
      },
    };
  },
  mounted: function() {
    this.breadcrumbList = [];
    this.loadBreadcrumb();
  },
  methods: {
    loadBreadcrumb: function() {
      try {
        var breadcrumb = this.$route.meta.breadcrumb;
        var currentPath = this.$route.path;
        if (breadcrumb != undefined) {
          if (breadcrumb.length > 1) {
            this.breadcrumbList = Utils.objectFilter(breadcrumb, { match : currentPath })[0];
          } else if (breadcrumb.length == 1) {
            this.breadcrumbList = breadcrumb[0];
          }
        } else {
          console.log("Invalid Breadcrumb : " + breadcrumb);
        }
      } catch (error) {}
    },
  },
  watch: {
    $route() {
      this.breadcrumbList = [];
      this.loadBreadcrumb();
    },
  },
};
</script>
