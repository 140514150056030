import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Default from './framework/layout/Default.vue';
import Admin from './framework/layout/Admin.vue';

import isURL from 'validator/lib/isURL';
import { ValidationObserver, ValidationProvider, extend, validate } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import VueSession from 'vue-session'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

import Multiselect from 'vue-multiselect'
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

window.chartColors = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(231,233,237)'
};

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.use(VueCodemirror, {
  events: ['scroll']
}
);

Vue.component('multiselect', Multiselect)

extend('positive', value => {
  return value >= 0;
});
const urlFixRule = (value) => {
  return isURL(value, {
      require_tld: false
  });
};

extend('url', urlFixRule);

var options = {
  persist: true
}
Vue.config.productionTip = false
export const bus = new Vue();

Vue.use(VueFormWizard)
Vue.use(VueSession, options)

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


Vue.component('admin-layout', Admin);
Vue.component('default-layout', Default);


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
