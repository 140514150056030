"use strict";

import { IdleSessionTimeout } from "idle-session-timeout";

//time out in 5 min on inactivity
let session = new IdleSessionTimeout(30 * 60 * 1000);

session.onTimeOut = () => {
    console.log("timeOut");
    window.location.href = '/';
}; 

//optional
/*session.onTimeLeftChange = (timeLeft) => {
    console.log(`${timeLeft} ms left`);
};*/

session.start();
 

// can be manually reset.
// -- session.reset();
// Note:when the session is expired, it's automatically disposed. 
// To reset the counter for expired session use start method.

// to dispose the session
// -- session.dispose();


// let timeLeft = session.getTimeLeft();

export default {
    session
}