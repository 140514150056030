<template>
  <div id="wrapper">
    <!-- Menu Data -->
    <Menu></Menu>
    <!-- End Menu Data -->

    <div id="page-wrapper" class="gray-bg">
      <!-- Header Data -->
      <Header></Header>
      <!-- End Header Data -->

      <!-- Breadcrumb -->
      <Breadcrumb :v-if="isReady"></Breadcrumb>
      <!-- Breadcrumb -->
      <router-view />
      <!-- Footer -->
      <Footer></Footer>
      <!-- Footer Emd -->
    </div>
  </div>
</template>

<script>
import Header from "../components/common/Header.vue";
import Menu from "../components/common/Menu.vue";
import Footer from "../components/common/Footer.vue";
import Breadcrumb from "../components/common/Breadcrumb.vue";

import Constant from "../plugin/Constant";

export default {
  data() {
    return {
      isReady: false,
    };
  },
  components: {
    Header,
    Menu,
    Footer,
    Breadcrumb,
  },
  mounted: function () {
    
  },
  methods: {
    resolveMenuCss: function (currentNav, parentNav) {
      localStorage.setItem(Constant.data.PREVIOUS_ROUTE, currentNav);
      $("#side-menu li").parent().find("li").removeClass("active");
      $("#" + currentNav).addClass("active");
      $("#side-menu li").parent().find("ul").removeClass("in");
      $("#ul_" + parentNav).addClass("in");
      $("#parent_" + parentNav).addClass("active");
    },
  },
  updated() {
    setTimeout(() => {
      var currentPath = this.$route.path;
      if (currentPath != undefined && currentPath != "/home") {
        var currentNav = "sub_" + currentPath.replace("/", "");
        var parentNav = $("#" + currentNav).attr("data-target");
        if (parentNav != undefined) {
          this.resolveMenuCss(currentNav, parentNav);
        } else if (
          localStorage.getItem(Constant.data.PREVIOUS_ROUTE) != undefined
        ) {
          var currentNav = localStorage.getItem(Constant.data.PREVIOUS_ROUTE);
          var parentNav = $("#" + currentNav).attr("data-target");
          this.resolveMenuCss(currentNav, parentNav);
        }
      }
    }, Constant.timeout);
  },
};
</script>
