<template>
  <nav class="navbar-default navbar-static-side" role="navigation">
    <div class="sidebar-collapse">
      <ul class="nav metismenu" id="side-menu">
        <li class="nav-header">
          <div class="dropdown profile-element">
            <img
              alt="image"
              class="rounded-circle user-profile animated fadeInDown"
              src="img/user-profile1.png"
            />
            <a data-toggle="dropdown" class="dropdown-toggle" href="#">
              <span class="block m-t-xs font-bold user-name"
                >{{ getUserName() }}</span
              >
              <span class="text-muted text-xs block user-role"
                >{{ roleName }} <b class="caret"></b
              ></span>
            </a>
            <ul class="dropdown-menu animated fadeInRight m-t-xs"> 
              <li><router-link class="dropdown-item" to="home">Profile</router-link></li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="/">Logout</a></li>
            </ul>
          </div>
          <div class="logo-element">
            REN
          </div>
        </li>
        <li v-for="(menuValue, menuKey) in groupMenuList" :key="menuKey" :id="'parent_'+menuKey">
          <a href="" v-if="menuValue.length > 0"
            ><i :class="getMenuIconById(menuKey)"></i>
            <span class="nav-label">{{ getMenuById(menuKey) }}</span>
            <span class="fa arrow"></span
          ></a>
          <ul class="nav nav-second-level collapse" :id="'ul_'+menuKey">
            <li
              v-for="(subMenuValue, subMenuKey) in menuValue"
              :key="subMenuKey"
              :id="'sub_'+subMenuValue.route.replace('/', '')"
              :data-target="menuKey"
            >
              <router-link :to="{ path: subMenuValue.route }">{{ subMenuValue.displayName }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import AxiosService from "../../plugin/AxiosService.js";
import Constant from "../../plugin/Constant.js";
var _ = require('lodash');

export default {
  data() {
    return {
      groupMenuList: [],
      menuList: localStorage.getItem(Constant.data.MENU_LIST),
      roleName: ''
    };
  },
  created: function() {
    this.fetchMenu();
  },
  mounted: function() {
    this.getRoleName();
    localStorage.setItem("fixedsidebar", "on");
    this.isReady = true;
      setTimeout(() => {
        $.when(
          $.getScript("js/plugins/metisMenu/jquery.metisMenu.js"),
          $.getScript("js/inspinia.js"),
          $.getScript("js/plugins/pace/pace.min.js"),
          $.Deferred(function (deferred) {
            $(deferred.resolve);
          })
        ).done(function () {
          console.log("Js Loaded Successfully");
        });
      }, Constant.timeout);
  },
  methods: {
    fetchMenu: function() {
      var userRole = localStorage.getItem(Constant.data.USER_ROLE);
      if(userRole != undefined) {
        var roleId = userRole.substring(0, userRole.indexOf(':'));
        AxiosService.get(
          Constant.url.ROLE_URI + Constant.SEPARATER + roleId + Constant.SEPARATER +Constant.url.MENU_URI
        ).then((result) => {
          this.groupMenuList = result;
        });
      }else {
        console.log('Invalid Role Id : '+userRole);
      }
    },
    getMenuById: function(key) {
      var filterMenu = _.filter(JSON.parse(this.menuList), { menu: key }).map(v => v.displayName);
      return filterMenu[0];
    },
    getMenuIconById: function(key) {
      var filterIcon = _.filter(JSON.parse(this.menuList), { menu: key }).map(v => v.icon);
      return filterIcon[0];
    },
    getUserName: function() {
      return localStorage.getItem(Constant.data.SESSION_USER);
    },
    getRoleName: function() {
      var self = this;
      setTimeout(() => {
        self.roleName = _.defaultTo(_.capitalize(localStorage.getItem(Constant.data.ROLE_NAME)), 'Administrator')  
        this.$forceUpdate();
      }, 500);
      
    }
  },
};
</script>

<style scoped>
.user-profile {
  width: 40%;
  height: 20%;
  margin: -5%;
  margin-top: -11%;
  margin-left: 23%;
}

.user-name {
  margin-top: 14px;
  margin-left: 18%;
}

.user-role {
  margin-left: 18%;
}
</style>
