import Vue from "vue";
import VueRouter from "vue-router";
import Utils from "../framework/plugin/Utils";
import Constant from "../framework/plugin/Constant";

Vue.use(VueRouter);

const Login = () =>
    import ( /* webpackChunkName: "Login" */ '../framework/login/Login.vue');
const Home = () =>
    import ( /* webpackChunkName: "Home" */ '../framework/profile/Home.vue');
const Bad = () =>
    import ( /* webpackChunkName: "Bad" */ '../framework/profile/Bad.vue');
const ViewCustomer = () =>
    import ( /* webpackChunkName: "ViewCustomer" */ '../framework/customer/ViewCustomer.vue');
const AddCustomer = () =>
    import ( /* webpackChunkName: "AddCustomer" */ '../framework/customer/AddCustomer.vue');
const ViewProperties = () =>
    import ( /* webpackChunkName: "ViewProperties" */ '../framework/properties/ViewProperties.vue');
const AddProperties = () =>
    import ( /* webpackChunkName: "AddProperties" */ '../framework/properties/AddProperties.vue');
const ViewGroup = () =>
    import ( /* webpackChunkName: "ViewGroup" */ '../framework/group/ViewGroup.vue');
const AddGroup = () =>
    import ( /* webpackChunkName: "AddGroup" */ '../framework/group/AddGroup.vue');
const ViewRole = () =>
    import ( /* webpackChunkName: "ViewRole" */ '../framework/role/ViewRole');
const AddRole = () =>
    import ( /* webpackChunkName: "AddRole" */ '../framework/role/AddRole');
const AddUser = () =>
    import ( /* webpackChunkName: "AddUser" */ '../framework/user/AddUser');
const ViewUser = () =>
    import ( /* webpackChunkName: "ViewUser" */ '../framework/user/ViewUser');
const AddApiDefinition = () =>
    import ( /* webpackChunkName: "AddApiDefinition" */ '../framework/api/AddApiDefinition');
const ViewApiDefinition = () =>
    import ( /* webpackChunkName: "ViewApiDefinition" */ '../framework/api/ViewApiDefinition');
const UpdateApiDefinition = () =>
    import ( /* webpackChunkName: "UpdateApiDefinition" */ '../framework/api/UpdateApiDefinition');
const ApiEndpoint = () =>
    import ( /* webpackChunkName: "ApiEndpoint" */ '../framework/endpoint/ApiEndpoint');
const AddEndpoint = () =>
    import ( /* webpackChunkName: "AddEndpoint" */ '../framework/endpoint/AddEndpoint');
const AddRoleEndpoint = () =>
    import ( /* webpackChunkName: "AddRoleEndpoint" */ '../framework/endpoint/AddRoleEndpoint');
const RoleEndpoint = () =>
    import ( /* webpackChunkName: "RoleEndpoint" */ '../framework/endpoint/RoleEndpoint');
const UiTheme = () =>
    import ( /* webpackChunkName: "UiTheme" */ '../framework/themes/UiTheme');
const UserAudit = () =>
    import ( /* webpackChunkName: "UserAudit" */ '../framework/audit/UserAudit');
const AddTest = () =>
    import ( /* webpackChunkName: "AddTest" */ '../framework/test_case/AddTest');
const ViewTest = () =>
    import ( /* webpackChunkName: "ViewTest" */ '../framework/test_case/ViewTest');
const SelectedTestCase = () =>
    import ( /* webpackChunkName: "SelectedTestCase" */ '../framework/test_case/SelectedTestCase');
const ApiAudit = () =>
    import ( /* webpackChunkName: "ApiAudit" */ '../framework/audit/ApiAudit');
const swagger_ui = () =>
    import ( /* webpackChunkName: "swagger_ui" */ '../framework/swagger_ui/swagger_ui');
const ServerDetails = () =>
    import ( /* webpackChunkName: "ServerDetails" */ '../framework/chart/ServerDetails');
const RequestDetails = () =>
    import ( /* webpackChunkName: "RequestDetails" */ '../framework/chart/RequestDetails');
const Page403 = () =>
    import ( /* webpackChunkName: "Page403" */ '../framework/error/Page403.vue');
const ViewMfaUser = () =>
    import ( /* webpackChunkName: "ViewMfaUser" */ '../framework/mfa/ViewMfaUser');
const AddMfaUser = () =>
    import ( /* webpackChunkName: "AddMfaUser" */ '../framework/mfa/AddMfaUser');



const routes = [{
        path: "/login",
        component: Login,
        meta: { layout: "default" },
    },
    {
        path: "/home",
        component: Home,
        meta: {
            layout: "admin",
            breadcrumb: [{ name: "Home", link: "/home", displayName: "Dashboard" }],
        },
    },
    {
        path: "/500",
        component: Bad,
        meta: { layout: "admin" },
    },
    {
        path: "/customer",
        component: ViewCustomer,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Customer", link: "/customer", displayName: "View Customer" },
            ],
        },
    },
    {
        path: "/addCustomer",
        alias: ["/updateCustomer"],
        component: AddCustomer,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Customer",
                    link: "/customer",
                    displayName: "Add Customer",
                    match: "/addCustomer",
                },
                {
                    name: "Customer",
                    link: "/customer",
                    displayName: "Update Customer",
                    match: "/updateCustomer",
                },
            ],
        },
    },
    {
        path: "/properties",
        component: ViewProperties,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Properties", link: "/properties", displayName: "View Properties" },
            ],
        },
    },
    {
        path: "/addProperties",
        alias: ["/updateProperties"],
        component: AddProperties,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Properties",
                    link: "/properties",
                    displayName: "Add Properties",
                    match: "/addProperties",
                },
                {
                    name: "Properties",
                    link: "/properties",
                    displayName: "Update Properties",
                    match: "/updateProperties",
                },
            ],
        },
    },
    {
        path: "/group",
        component: ViewGroup,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Group", link: "/group", displayName: "View Group" },
            ],
        },
    },
    {
        path: "/addGroup",
        alias: ["/updateGroup"],
        component: AddGroup,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Group",
                    link: "/group",
                    displayName: "Add Group",
                    match: "/addGroup",
                },
                {
                    name: "Group",
                    link: "/group",
                    displayName: "Update Group",
                    match: "/updateGroup",
                },
            ],
        },
    },
    {
        path: "/role",
        component: ViewRole,
        meta: {
            layout: "admin",
            breadcrumb: [{ name: "Role", link: "/role", displayName: "View Role" }],
        },
    },
    {
        path: "/addRole",
        alias: ["/updateRole"],
        component: AddRole,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Role",
                    link: "/role",
                    displayName: "Add Role",
                    match: "/addRole",
                },
                {
                    name: "Role",
                    link: "/role",
                    displayName: "Update Role",
                    match: "/updateRole",
                },
            ],
        },
    },
    {
        path: "/user",
        component: ViewUser,
        meta: {
            layout: "admin",
            breadcrumb: [{ name: "User", link: "/user", displayName: "View User" }],
        },
    },
    {
        path: "/addUser",
        alias: ["/updateUser"],
        component: AddUser,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "User",
                    link: "/user",
                    displayName: "Add User",
                    match: "/addUser",
                },
                {
                    name: "User",
                    link: "/user",
                    displayName: "Update User",
                    match: "/updateUser",
                },
            ],
        },
    },
    {
        path: "/apiDefinition",
        component: ViewApiDefinition,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "API", link: "/apiDefinition", displayName: "Add API" },
            ],
        },
    },
    {
        path: "/addApi",
        component: AddApiDefinition,
        meta: {
            layout: "admin",
            breadcrumb: [{
                name: "API",
                link: "/apiDefinition",
                displayName: "Add API",
                match: "/addApi",
            }, ],
        },
    },
    {
        path: "/updateApi",
        component: UpdateApiDefinition,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "API", link: "/apiDefinition", displayName: "Update API" },
            ],
        },
    },
    {
        path: "/apiEndpoint",
        component: ApiEndpoint,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Endpoint", link: "/apiEndpoint", displayName: "Api Endpoint" },
            ],
        },
    },
    {
        path: "/addEndpoint",
        alias: ["/updateEndpoint"],
        component: AddEndpoint,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Endpoint",
                    link: "/apiEndpoint",
                    displayName: "Add Endpoint",
                    match: "/addEndpoint",
                },
                {
                    name: "Endpoint",
                    link: "/apiEndpoint",
                    displayName: "Update Endpoint",
                    match: "/updateEndpoint",
                },
            ],
        },
    },
    {
        path: "/roleEndpoint",
        component: RoleEndpoint,
        meta: {
            layout: "admin",
            breadcrumb: [{
                name: "Role Endpoint",
                link: "/roleEndpoint",
                displayName: "Role Endpoint",
            }, ],
        },
    },
    {
        path: "/addRoleEndpoint",
        alias: ["/updateRoleEndpoint"],
        component: AddRoleEndpoint,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Role Endpoint",
                    link: "/roleEndpoint",
                    displayName: "Add Role Endpoint",
                    match: "/addRoleEndpoint",
                },
                {
                    name: "Role Endpoint",
                    link: "/roleEndpoint",
                    displayName: "Update Role Endpoint",
                    match: "/updateRoleEndpoint",
                },
            ],
        },
    },
    {
        path: "/uiThemes",
        component: UiTheme,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "UI Themes", link: "/uiThemes", displayName: "UI Themes" },
            ],
        },
    },
    {
        path: "/userAudit",
        component: UserAudit,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "User Audit", link: "/userAudit", displayName: "User Audit" },
            ],
        },
    },
    {
        path: "/testCase",
        component: ViewTest,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Test Case", link: "/testCase", displayName: "View Test Case" },
            ],
        },
    },
    {
        path: "/addTestCase",
        alias: ["/updateTestCase"],
        component: AddTest,
        meta: {
            layout: "admin",
            breadcrumb: [{
                    name: "Test Case",
                    link: "/testCase",
                    displayName: "Add Test Case",
                    match: "/addTestCase",
                },
                {
                    name: "Test Case",
                    link: "/testCase",
                    displayName: "Update Test Case",
                    match: "/updateTestCase",
                },
            ],
        },
    },
    {
        path: "/selectedTestCase",
        component: SelectedTestCase,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Test Case", link: "/testCase", displayName: "View Test Case" },
            ],
        },
    },
    {
        path: "/apiAudit",
        component: ApiAudit,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "API Audit", link: "/apiAudit", displayName: "API Audit" },
            ],
        },
    },
    {
        path: "/swaggerUi",
        component: swagger_ui,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Swagger Ui", link: "/swaggerUi", displayName: "Swagger Ui" },
            ],
        },
    },
    {
        path: "/serverDetails",
        component: ServerDetails,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Mertics", link: "/serverDetails", displayName: "Swagger Ui" },
            ],
        },
    },
    {
        path: "/requestDetails",
        component: RequestDetails,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "Mertics", link: "/swaggerUi", displayName: "Request Mertics" },
            ],
        },
    },
    {
        path: "/mfauser",
        component: ViewMfaUser,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "MfaUser", link: "/mfauser", displayName: "Mfa User" },
            ],
        },
    },
    {
        path: "/addMfaUser",
        alias: ["/updateMfaUser"],
        component: AddMfaUser,
        meta: {
            layout: "admin",
            breadcrumb: [
                { name: "MfaUser", link: "/mfauser", displayName: "Mfa User" },
            ],
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


const landingPage = '/login';

router.beforeEach((to, from, next) => {
    try {
        if (to.meta != undefined) {
            if (to.path.includes('login')) {
                to.meta.layout = 'default';
            } else {
                if(to.path === "/") {
                    return next(landingPage);
                }
                var userDetails = sessionStorage.getItem(Constant.data.SESSION_TOKE)
                if (Utils.isEmptyStr(userDetails)) {
                    return next(landingPage);
                }
            }
        }else if(to.path === "/") {
            return next(landingPage);
        }else {
            return next(landingPage);
        }
    } catch (error) {
        console.error(error);
        return next(landingPage);
    }
    return next();
})


export default router;