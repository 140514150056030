<template>
<div class="row border-bottom">
    <nav class="navbar navbar-static-top  " role="navigation" style="margin-bottom: 0">
        <div class="navbar-header" >
            <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#"><i class="fa fa-bars"></i>
            </a>
            <div class="form-group">
                <img src="img/logo.svg" class="ren-logo animated fadeInDown" />
            </div>
        </div>
        <ul class="nav navbar-top-links navbar-right">
            <li>
                <span class="m-r-sm text-muted welcome-message"><i class="fa fa-hourglass-half " :class="timerClass" aria-hidden="true"></i> &nbsp;&nbsp; Session expires in {{ sessionTimeoutMin }} min {{ sessionTimeoutSec }} secs</span>
            </li>
            <li>
                <a href="/" @click="logout()"> <i class="fa fa-sign-out"></i> Log out </a>
            </li>
        </ul>
    </nav>
</div>
</template>
<script>
import IdleSessionTimeout from '../../plugin/IdleSessionTimeout';
export default {
    data() {
        return {
            sessionTimeoutMin: '',
            sessionTimeoutSec: '',
            timerClass: '',
        }
    },
    created: function(){
        setInterval(() => {
            //this.timerClass = "animated flash";
            var currentTime = IdleSessionTimeout.session.getTimeLeft();
            this.sessionTimeoutMin = Math.floor( currentTime / 60000);
            this.sessionTimeoutSec = (( currentTime % 60000) / 1000).toFixed(0);
            if(this.sessionTimeoutMin < 0 && this.sessionTimeoutSec < 0) {
                IdleSessionTimeout.session.dispose();
            }
            if(currentTime % 2 == 0 ) {
                //this.timerClass = ""; 
            }
        }, 1000);
    },
    methods: {
        lastLoginDisplay: function() {
            console.log('lastLoginDisplay calling...');
        },
        logout: function() {
            sessionStorage.clear();
        }
    },
}
</script>
<style scoped>
.ren-logo {
    width: 12%;
    margin-top: 2%;
    margin-left: 3%;
    max-width: 100%;
}
.nav > li.active {
    border-left: 0px solid #19aa8d !important;
    background: #f3f3f4 !important;
}
.nav > li.active > a {
    color: #999c9e !important;
}
</style>
