"use strict";
import Constant from "./Constant";
import Utils from "./Utils";

const axios = require("axios");

var baseURL = "";
// var baseURL="api/";


let config = {
    mode: "no-cors",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "userId": localStorage.getItem(Constant.data.USER_ID.toString())
    },
    withCredentials: false,
    credentials: "same-origin",
};

function getSessionURL(params) {
    let url = sessionStorage.getItem(Constant.data.SERVER_URL);
    if (!Utils.isEmptyStr(url)) {
        return url;
    }
    return baseURL;
}

toastr.options = {
    closeButton: true,
    progressBar: true,
    showDuration: "400",
    hideDuration: "1000",
    timeOut: "7000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
};

const get = async function(uri) {
    if (!Utils.isEmptyStr(sessionStorage.getItem(Constant.data.SESSION_TOKE))) {
        config.headers["Authorization"] = "Bearer " + await sessionStorage.getItem(Constant.data.SESSION_TOKE);
    }
    let url = await getSessionURL() + uri;
    return axios.get(url, config).then((response) => {
        return JSON.parse(JSON.stringify(response.data));
    }).catch(function(error) {
        toastr.error(error, "Error");
        return error;
    });
};


const getURL = async function(uri) { 
    let url = uri;
    config.method = 'get';
    return axios(url, config).then((response) => {
        return JSON.parse(JSON.stringify(response.data));
    }).catch(function(error) {
        console.warn('URL is not configured !');
        return "";
    });
};


const post = async function(uri, formData) {
    var config = {
        method: "post",
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "userId": localStorage.getItem(Constant.data.USER_ID.toString())
        },

        withCredentials: false,
        credentials: "same-origin",
        data: formData,
    };

    if (!Utils.isEmptyStr(sessionStorage.getItem(Constant.data.SESSION_TOKE))) {
        config.headers["Authorization"] = "Bearer " + await sessionStorage.getItem(Constant.data.SESSION_TOKE);
    }
    let url = await getSessionURL() + uri;
    return axios(url, config)
        .then(function(response) {
            var data = response.data;
            if ((data.hasOwnProperty('responseCode') && data.responseCode != "00") || response.status != 200) {
                toastr.error(data.responseMsg, "Error");
            }
            return data;
        })
        .catch(function(error) {
            toastr.error(error, "Error");
            return error;
        });
};



const postSwagger = function(uri, formData) {
    var config = {
        method: "post",
        url: uri,
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },

        withCredentials: false,
        credentials: "same-origin",
        data: formData,
    };
    return axios(config)
        .then(function(response) {
            var data = response.data;
            return data;
        })
        .catch(function(error) {
            return error;
        });
};

const authPost = async function(uri, formData) {
    var config = {
        method: "post",
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'userId': localStorage.getItem(Constant.data.USER_ID),
        },

        withCredentials: false,
        credentials: "same-origin",
        data: formData,
    };
    let url = await getSessionURL() + uri;
    return axios(url, config)
        .then(function(response) {
            var data = response.data;
            if (data.responseCode != "00") {
                toastr.error(data.responseMsg, "Error");
            }
            return data;
        })
        .catch(function(error) {
            toastr.error(error, "Error");
            return error;
        });
};

const postQuery = async function(uri, data) {
    if (!Utils.isEmptyStr(sessionStorage.getItem(Constant.data.SESSION_TOKE))) {
        config.headers["Authorization"] = "Bearer " + await sessionStorage.getItem(Constant.data.SESSION_TOKE);
    }
    let url = await getSessionURL() + uri + "?" + data;
    return axios
        .post(url, config)
        .then((response) => {
            return JSON.parse(JSON.stringify(response.data));
        })
        .catch(function(error) {
            return error;
        });
};

const deleteQuery = async function(uri, data) {
    if (!Utils.isEmptyStr(sessionStorage.getItem(Constant.data.SESSION_TOKE))) {
        config.headers["Authorization"] = "Bearer " + await sessionStorage.getItem(Constant.data.SESSION_TOKE);
    }
    let url = await getSessionURL() + uri + "?" + data;
    return axios.delete(url, config).then((response) => {
        return JSON.parse(JSON.stringify(response.data));
    });
};

const deleteData = async function(uri) {
    var config = {
        method: "delete",
        mode: "no-cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'userId': localStorage.getItem(Constant.data.USER_ID),
        },

        withCredentials: false,
        credentials: "same-origin",
        data: '',
    }; 

    if (!Utils.isEmptyStr(sessionStorage.getItem(Constant.data.SESSION_TOKE))) {
        config.headers["Authorization"] = "Bearer " + await sessionStorage.getItem(Constant.data.SESSION_TOKE);
    }
    let url = await getSessionURL() + uri;
    return axios(url, config)
        .then(function(response) {
            var data = response.data;
            if (data.responseCode != "00") {
                toastr.error(data.responseMsg, "Error");
            } else {
                toastr.success(data.responseMsg, "Success");
            }
            return data;
        })
        .catch(function(error) {
            toastr.error(error, "Error");
            return error;
        });
};


export default {
    get,
    post,
    postQuery,
    deleteQuery,
    authPost,
    deleteData,
    postSwagger,
    getURL
};
